<template>
  <div
    class="spinner shadow-1 p-3"
    v-html="svg"></div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import svg from "@/app/assets/images/marque-circle.svg?raw";

export default {
  name: "LoadingSpinner",
  data() {
    return {
      svg
    };
  }
};
</script>
